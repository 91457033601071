<script setup>
const imageItem = [
    {
        image: '/article-with-button-image1.webp',
        text: 'Канальный кондиционер'
    },
    {
        image: '/article-with-button-image2.webp',
        text: 'Круглый воздуховод вентиляции'
    },
    {
        image: '/article-with-button-image3.webp',
        text: 'Проект вентиляции'
    },
    {
        image: '/article-with-button-image4.webp',
        text: 'Вытяжная решетка вентиляции'
    }
];
const emits = defineEmits(['openPopUpApplication']);

const handlerOpenPopUpApplication = () => {
    emits('openPopUpApplication');
};
</script>

<template>
    <section id="comfort" class="comfort-medium">
        <div class="comfort-medium__content-wrapper">
            <div class="comfort-medium__content">
                <ArticleBlockWithButtonMedium class="comfort-medium__article"
                    @openPopUpApplication="handlerOpenPopUpApplication" />
                <div class="comfort-medium__image-wrapper">
                    <div v-for="(item, index) in imageItem" class="comfort__image-block" itemscope
                        itemtype="http://schema.org/ImageObject">
                        <span itemprop="license"
                            content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"></span>
                        <span itemprop="copyrightNotice"
                            content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"></span>
                        <span itemprop="creator" itemtype="https://schema.org/Organization" itemscope>
                            <span itemprop="name" content="https://www.freepikcompany.com/"></span>
                        </span>
                        <span itemprop="creditText"
                            content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"></span>
                        <span itemprop="acquireLicensePage"
                            content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"></span>
                        <span itemprop="name" :content="item.text"></span>
                        <span itemprop="description" :content="item.text"></span>
                        <NuxtImg itemprop="contentUrl" :key="item.image" :src="item.image" :alt="item.text" :title="`Изображение ${item.text}`"
                            format="webp" class="comfort-medium__image" :class="[`comfort-medium__image_${index}`]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="less">
.comfort-medium {
    height: 890px;
    padding-top: 80px;
    background-color: #ffffff;

    &__content {
        width: 944px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: center right / 718px 718px no-repeat url('@/assets/img/comfort-block-background.svg');

        &-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__image {
        &_0 {
            width: 235px;
            height: 375px;
        }

        &_1 {
            width: 235px;
            height: 350px;
            padding-top: 68px;
        }

        &_2 {
            width: 235px;
            height: 385px;
        }

        &_3 {
            width: 235px;
            height: 294px;
            margin-top: -22px;
        }

        &-wrapper {
            width: 475px;
            height: 760px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
</style>
